import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import Moment from 'moment';
import 'moment/locale/pt-br';
import './listing.less';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
const NewsItemsTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  return (
    <>
      <div className="items">
        {items.map((item, index) => (
          <div className="listing-item" key={item['@id']}>
            <div className="listing-div">
              <img
                src={
                  item.image_scales?.image?.[0]?.scales?.preview?.download
                    ? item.image_scales.image[0].scales.preview.download
                    : DefaultImageSVG
                }
                alt={item.title}
                className="listing-img"
              />
              <div className="listing-body">
                <ConditionalLink item={item} condition={!isEditMode} className="listing-item-main-link">
                  <div className="listing-chapeu">{item?.chapeu}</div>
                  <h4 className="listing-title">{item.title ? item.title : item.id}</h4>
                  <p className="listing-description">{item.description}</p>
                </ConditionalLink>
                {item?.Subject?.length > 0 && (
                  <div className="listing-tags">
                    <span style={{ fontWeight: 700, fontSize: 14 }}>tags: &nbsp;&nbsp;</span>
                    <ul className="listing-list">
                      {item?.Subject?.map((tag, index) => (
                        <li>
                          <a href="#/" className="listing-tag" key={'lt_' + index}>
                            {tag}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <p className="listing-effective">
                  {Moment(item?.created_import ?? item?.created).format('DD/MM/YYYY - HH:mm')}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

NewsItemsTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default NewsItemsTemplate;
